.footer__back {
  box-shadow: 0px 1px 17px 0px #c2c2c240;
  background: #fff;
  position: relative;
}
.footer__box {
  padding: 30px 0;
}
.footer__li a {
  font-size: 14px;
  line-height: 21px;
  color: #5656566b;
}
.footer__li a:hover {
  color: #02308c;
}
.footer__title h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #565656;
}
.footer__soc a i {
  font-size: 30px;
}
.footer__soc a {
  color: #02308c;
}
.footer__soc a:hover {
  color: #ff1b1b;
}
.footer__logo a {
  font-size: 24px;
  color: #fff;
}
.footer__back i.fa.fa-chevron-up {
  display: none;
}
.response__app {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 1000px) {
  .footer__logo {
    margin-bottom: 1rem;
  }
  .footer__logo a img {
    margin: 0 !important;
  }
  .footer__soc {
    justify-content: unset;
  }
  .footer__title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .footer__title h5 {
    font-size: 22px;
  }
  .footer__ul {
    margin: 10px 0;
  }
  .footer__back i.fa.fa-chevron-up {
    display: block;
    position: absolute;
    bottom: 25px;
    right: 25px;
    font-size: 18px;
    border-radius: 50%;
    background: #5e8ce2;
    color: #fff;
    padding: 18px;
  }
  .footer__li {
    margin: 3px 0;
  }
  .total__price {
    font-size: 30px;
  }
  .response__app {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 45%;
    right: 15px;
    .footer__title {
      display: none;
    }
  }
}
@media (max-width: 330px) {
  .footer__back i.fa.fa-chevron-up {
    bottom: 195px;
    right: 30px;
    padding: 15px;
    font-size: 26px;
  }
}

.footer-link-app {
  max-width: 160px;
  margin-top: 8px;
}

.vkontact {
  filter: invert(92%) sepia(58%) saturate(6713%) hue-rotate(214deg)
    brightness(99%) contrast(107%);
}
.vkontact-contact {
  filter: invert(90%) sepia(8%) saturate(430%) hue-rotate(314deg)
    brightness(97%) contrast(91%);
}
