@keyframes ldio-49gy765oit4 {
   0% {
      transform: rotate(0deg);
   }
   50% {
      transform: rotate(180deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
.ldio-49gy765oit4 div {
   position: absolute;
   animation: ldio-49gy765oit4 1s linear infinite;
   width: 83.6px;
   height: 83.6px;
   top: 53.199999999999996px;
   left: 53.199999999999996px;
   border-radius: 50%;
   box-shadow: 0 4.75px 0 0 #131e3d;
   transform-origin: 41.8px 44.175px;
}
.loadingio-spinner-eclipse-0yeinnyikbjq {
   width: 190px;
   height: 190px;
   display: inline-block;
   overflow: hidden;
   background: none;
}
.ldio-49gy765oit4 {
   width: 100%;
   height: 100%;
   position: relative;
   transform: translateZ(0) scale(1);
   backface-visibility: hidden;
   transform-origin: 0 0;
}
.ldio-49gy765oit4 div {
   box-sizing: content-box;
}
