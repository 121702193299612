.react-responsive-modal-modal {
  max-width: 600px;
  width: 500px;
  max-height: 600px;
  border-radius: 1rem;
  padding: 0 !important;
}
.react-responsive-modal-container {
  overflow: hidden !important;
}
.error-modal {
  background: #fff;
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 540px) {
    width: 95%;
  }
  img {
    width: 100%;
    max-height: 200px;
  }
}
.error-modal ~ .react-responsive-modal-closeButton {
  background: rgba($color: #fff, $alpha: 0.5) !important;
  border-radius: 5px !important;
  &:hover {
    background: #fff !important;
  }
  @media (max-width: 769px) {
    background: #fff !important;
  }
}
